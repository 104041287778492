.notice {
  padding: 15px;
  background-color: #fafafa;
  border-left: 6px solid #7f7f84;
  margin-bottom: 10px;
  -webkit-box-shadow: 0 5px 8px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 8px -6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 8px -6px rgba(0, 0, 0, 0.2);
}

.notice-success {
  border-color: #80d651;
}
.notice-success > strong {
  color: #80d651;
}
.notice-info {
  border-color: #45abcd;
}
.notice-info > strong {
  color: #45abcd;
}
.notice-warning {
  border-color: #feaf20;
}
.notice-warning > strong {
  color: #feaf20;
}
.notice-danger {
  border-color: #d73814;
}
.notice-danger > strong {
  color: #d73814;
}
.notice-primary {
  border-color: #0557d2;
}
.notice-primary > strong {
  color: #0557d2;
}
